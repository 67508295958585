type TProps = {
  id: string;
};

export const getImpactCDNScript = ({ id }: TProps) => `
	(function(i,m,p,a,c,t){c.ire_o=p;c[p]=c[p]||function(){(c[p].a=c[p].a||[]).push(arguments)};
	t=a.createElement(m);var z=a.getElementsByTagName(m)[0];t.async=1;t.src=i;z.parentNode.insertBefore(t,z)})
	('https://utt.impactcdn.com/P-${id}.js','script','impactStat',document,window);
	impactStat('transformLinks');impactStat('trackImpression');
`;

export const ImpactCDNSiteVerification: React.FC<TProps> = ({ id }) => (
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  <meta name="impact-site-verification" value={id} />
);
