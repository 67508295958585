import { type IncomingMessage } from 'node:http';
import { canUseDom } from '../dom/can-use-dom';
import { type InsuranceHost } from './types';
import { getSsrInsuranceHost } from './get-ssr-insurance-host';
import { getBrowserInsuranceHost } from './get-browser-insurance-host';

export const getInsuranceHost = (req?: IncomingMessage): InsuranceHost => {
  if (canUseDom()) {
    return getBrowserInsuranceHost();
  }

  return getSsrInsuranceHost(req);
};
