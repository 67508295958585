var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"S-56AYM--KfDn5jLyA3-V"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

/**
 * Temporary comment client-side Sentry initialization
 * TODO: revert it
 */

// import * as Sentry from '@sentry/nextjs';

// Sentry.init({
// 	dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,

// 	// Adjust this value in production, or use tracesSampler for greater control
// 	tracesSampleRate: 1,

// 	// Setting this option to true will print useful information to the console while you're setting up Sentry.
// 	debug: false,

// 	replaysOnErrorSampleRate: 1,

// 	// This sets the sample rate to be 10%. You may want this to be 100% while
// 	// in development and sample at a lower rate in production
// 	replaysSessionSampleRate: 0.1,

// 	// You can remove this option if you're not planning to use the Sentry Session Replay feature:
// 	integrations: [
// 		new Sentry.Replay({
// 			// Additional Replay configuration goes in here, for example:
// 			maskAllText: true,
// 			blockAllMedia: true,
// 		}),
// 	],
// });
