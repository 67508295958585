import { stringifyUrlSearchParams } from './stringify-url-search-params';

export const getUrlWithParams = (
  url: string,
  params: Record<string, unknown>,
): string => {
  const stringifiedParams = stringifyUrlSearchParams(params);

  if (!stringifiedParams) {
    return url;
  }

  return `${url}${url.includes('?') ? '&' : '?'}${stringifiedParams}`;
};
