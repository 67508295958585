import { useEffect } from 'react';
import { initSplitTest } from '@/services/split-test';
import getConfig from 'next/config';
import { type TAppConfig } from '@insurance/app-config/src/types';

const { publicRuntimeConfig } = getConfig();

export const useInitSplitTest = (appConfig: TAppConfig) => {
  useEffect(() => {
    const { formBuildTag, formSplitTestVersion, landingSplitTestVersion } =
      appConfig;

    initSplitTest({
      project: 'form',
      version: formBuildTag,
      variant: formSplitTestVersion,
    });

    initSplitTest({
      project: 'landing',
      version: publicRuntimeConfig.buildTag,
      variant: landingSplitTestVersion,
    });
  }, [appConfig]);
};
