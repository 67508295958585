import { getSessionId } from '@insurance/session-id';
import { logger } from '@/utils/logger';
import { postSplitTestVersion } from '@/api/post-split-test-version';

type TInitSplitTestProps = {
  project: 'form' | 'landing';
  version: string;
  variant?: string;
};

export const initSplitTest = async ({
  project,
  version,
  variant,
}: TInitSplitTestProps) => {
  try {
    await postSplitTestVersion({
      sessionId: getSessionId(),
      project,
      version,
      variant,
    });
  } catch (error) {
    logger.error(error);
  }
};
