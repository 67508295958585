import { InsuranceHost } from '@insurance/utils';
import { useAppConfig } from '@insurance/app-config';

export const useDescriptionMetaTag = (): string => {
  const { host } = useAppConfig();

  switch (host) {
    case InsuranceHost.Requote:
      return 'Find the cheapest car insurance rates at Requote.com. Save up to $980 a year by comparing car insurance quotes. Get your personalized rates today at ReQuote!';
    default:
      return '';
  }
};
