import type { TContactPhone } from './types';
import { FACEBOOK_AID, TIK_TOK_AID } from './aids';
import {
  contactPhoneFacebook,
  contactPhoneQuinstreet,
  contactPhoneTikTok,
  defaultContactPhone,
} from './contacts';

export const getContactPhone = (aid?: number): TContactPhone => {
  switch (aid) {
    case TIK_TOK_AID:
      return contactPhoneTikTok;
    case FACEBOOK_AID:
      return contactPhoneFacebook;
    default:
      return defaultContactPhone;
  }
};

export const getQuinstreetContactPhone = (): TContactPhone =>
  contactPhoneQuinstreet;
