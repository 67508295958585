import { type IncomingMessage } from 'node:http';
import { type InsuranceHost } from './types';
import { DEFAULT_INSURANCE_HOST } from './constants';

export const getSsrInsuranceHost = (req?: IncomingMessage): InsuranceHost => {
  let host = '';
  if (typeof req?.headers['x-forwarded-host'] === 'string') {
    host = req.headers['x-forwarded-host'];
  }

  return (host || DEFAULT_INSURANCE_HOST) as InsuranceHost;
};
