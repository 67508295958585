import { type TAdjustParams, type TUtmParams } from '@insurance/utils';

interface TPostLeadVisitData {
  sessionId: string;
  aid?: number;
  source: string;
  clickId: string;
  app: string;
  utmParams: TUtmParams;
  googleData: Record<string, string>;
  adjust: TAdjustParams;
  referer: string;
}

export const postLeadVisit = async (data: TPostLeadVisitData) => {
  const response = await fetch('/api/lead/visit/', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error(`Lead visit posting failed with status ${response.status}`);
  }
};
