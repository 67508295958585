import { getUrlParam } from './get-url-param';
import { InsuranceHost } from './types';

export const getFormAppNameFromQuery = (): string => {
  const formAppName = getUrlParam('formAppName');
  if (typeof formAppName === 'string') {
    return formAppName;
  }

  if (Object.values<string>(InsuranceHost).includes(window.location.host)) {
    return window.location.host;
  }

  return 'myinsurancemarket';
};
