export type TPostSplitTestVersionParams = {
  sessionId: string;
  project: 'form' | 'landing';
  version: string;
  variant?: string;
};

export const postSplitTestVersion = async ({
  sessionId,
  project,
  version,
  variant,
}: TPostSplitTestVersionParams) => {
  const response = await fetch('/api/split-test/post-version/', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      sessionId,
      version,
      project,
      ...(variant ? { variant } : {}),
    }),
  });

  if (!response.ok) {
    throw new Error(
      `Split test version posting failed with status ${response.status}`,
    );
  }
};
