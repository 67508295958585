import { InsuranceHost } from './types';

export const URL_PARAMS_ALIASES: Record<string, string[]> = {
  leadId: ['lead_id'],
  clickId: ['token', 'click_id', 'gclid', 'fbclid', 'ttclid', 'msclkid'],
  title: ['template_title'],
  sub_title: ['template_sub_title'],
  button_text: ['template_button_text'],
  zipCode: ['zip', 'zip_code'],
};

export const DEFAULT_INSURANCE_HOST: InsuranceHost =
  InsuranceHost.MyInsuranceMarket;
