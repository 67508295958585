// eslint-disable-next-line check-file/filename-naming-convention
import '@/styles/globals.css';
import type { AppProps } from 'next/app';
import { canUseDom, setGtagGlobals } from '@insurance/utils';
import { getSessionId } from '@insurance/session-id';
import { SessionIdProvider } from '@/components/session-id-provider';
import { ErrorBoundary } from '@/components/error-boundary';
import { AppLayout } from '@/components/app-layout';
import { useInitSplitTest } from '@/hooks/use-init-split-test';
import { getAppConfig } from '@insurance/app-config';
import { AppConfigProvider } from '@insurance/app-config/src/app-config-provider';
import { type TAppConfig } from '@insurance/app-config/src/types';

type TApplicationProps = AppProps & { appConfigSSR?: TAppConfig };

if (canUseDom()) {
  setGtagGlobals({
    external_id: getSessionId(),
  });
}

export default function App({
  Component,
  pageProps,
  appConfigSSR,
}: TApplicationProps) {
  const appConfig = getAppConfig(appConfigSSR);

  useInitSplitTest(appConfig);

  return (
    <ErrorBoundary>
      <SessionIdProvider>
        <AppConfigProvider config={appConfig}>
          <AppLayout>
            <Component {...pageProps} />
          </AppLayout>
        </AppConfigProvider>
      </SessionIdProvider>
    </ErrorBoundary>
  );
}
