import { useContext } from 'react';
import { canUseDom } from '@insurance/utils';
import type { TAppConfig } from './types';
import { AppConfigContext } from './app-config-provider';

export const getAppConfig = (appConfigSSR?: TAppConfig): TAppConfig => {
  if (canUseDom()) {
    return window._ins_app_config_;
  }

  if (!appConfigSSR) {
    throw new Error('Landing config on a server is missing');
  }

  return appConfigSSR;
};

export const getAppConfigScript = (appConfigSSR: TAppConfig): string =>
  `window._ins_app_config_ = ${JSON.stringify(appConfigSSR)};`;

export const useAppConfig = (): TAppConfig => useContext(AppConfigContext);
