import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { getSessionId } from '@insurance/session-id';
import {
  canUseDom,
  getAidFromQuery,
  getClickIdFromQuery,
  getFormAppNameFromQuery,
  getGoogleCookies,
  getSourceFromQuery,
  getUtmParamsFromQuery,
} from '@insurance/utils';
import { postLeadVisit } from '@/api/post-lead-visit';
import { logger } from '@/utils/logger';
import { getAdjustParamsFromQuery } from '@insurance/utils/src/url/get-adjust-params-from-query';

const trackVisit = async (): Promise<void> => {
  try {
    await postLeadVisit({
      sessionId: getSessionId(),
      aid: getAidFromQuery(),
      clickId: getClickIdFromQuery(),
      source: getSourceFromQuery(),
      app: getFormAppNameFromQuery(),
      utmParams: getUtmParamsFromQuery(),
      googleData: getGoogleCookies(),
      adjust: getAdjustParamsFromQuery(),
      referer: document.referrer,
    });
  } catch (error) {
    logger.error(error);
  }
};

/**
 * Track visit only on client side, on server side fallback into Promise stub
 * Don't use React hooks here to reduce waiting time
 */
const initPromise = canUseDom() ? trackVisit() : Promise.resolve();

/**
 * Shared object between landing and form
 */
if (canUseDom()) {
  window._ins_car_init_promise_ = initPromise;
}

export interface TSessionIdContextValue {
  sessionId: string;
  initialized: boolean;
}

export const SessionIdContext = createContext<TSessionIdContextValue>({
  get sessionId() {
    return '';
  },
  get initialized() {
    return false;
  },
});

export const SessionIdProviderBrowser: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    initPromise.then(() => {
      setInitialized(true);
    });
  }, []);

  const value = useMemo<TSessionIdContextValue>(
    () => ({ sessionId: getSessionId(), initialized }),
    [initialized],
  );

  return (
    <SessionIdContext.Provider value={value}>
      {children}
    </SessionIdContext.Provider>
  );
};

export const SessionIdProviderServer: React.FC<React.PropsWithChildren> = ({
  children,
}) => children;

export const SessionIdProvider = canUseDom()
  ? SessionIdProviderBrowser
  : SessionIdProviderServer;

export const useSessionId = (): TSessionIdContextValue =>
  useContext(SessionIdContext);
