import React from 'react';
import Head from 'next/head';

import { DEFAULT_INSURANCE_HOST, InsuranceHost } from '@insurance/utils';
import { ImpactCDNSiteVerification } from '@/components/impactcdn';
import { useDescriptionMetaTag } from '@/hooks/use-description-meta-tag';
import { useAppConfig } from '@insurance/app-config';

const impactCDNOldId = process.env.NEXT_PUBLIC_IMPACT_CDN_OLD_ID;
const impactCDNId = process.env.NEXT_PUBLIC_IMPACT_CDN_ID;

const hostToIconsPathMap: Record<InsuranceHost, string> = {
  [InsuranceHost.Requote]: 'requote',
  [InsuranceHost.CarSurefy]: 'requote',
  [InsuranceHost.MyInsuranceMarket]: 'myinsurance',
  [InsuranceHost.Scalefind]: 'scalefind',
  [InsuranceHost.MyAutoArmor]: 'myautoarmor',
  [InsuranceHost.AllCheapQuotes]: 'myautoarmor',
  [InsuranceHost.SolaceAuto]: 'myautoarmor',
  [InsuranceHost.PragmaAuto]: 'myautoarmor',
};

export const AppLayout: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { host } = useAppConfig();
  const isRequoteHost = host === InsuranceHost.Requote;

  const iconsPath =
    hostToIconsPathMap[host] ?? hostToIconsPathMap[DEFAULT_INSURANCE_HOST];

  const faviconPath = `/icons/${iconsPath}/favicon.ico`;
  const appleTouchIconPath = `/icons/${iconsPath}/apple-touch-icon.png`;

  const metaDescription = useDescriptionMetaTag();

  return (
    <div id="app">
      <Head>
        <meta charSet="UTF-8" />
        {metaDescription && (
          <meta name="description" content={metaDescription} />
        )}
        {isRequoteHost && (
          <meta
            name="p:domain_verify"
            content="32e5397a5da14d6df6cf7bb741f52aa2"
          />
        )}
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="fo-verify" content="dfbe832b-20cb-4fbb-ad9b-af603ff315e6" />
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-expect-error */}
        <meta name="ir-site-verification-token" value="-1844546869" />
        <meta name="verification" content="dc5936d4f1cdff202c382a905c64a909" />

        {impactCDNOldId && (
          <ImpactCDNSiteVerification
            key="old-impact-verification"
            id={impactCDNOldId}
          />
        )}

        {impactCDNId && (
          <ImpactCDNSiteVerification
            key="new-impact-verification"
            id={impactCDNId}
          />
        )}

        <link rel="apple-touch-icon" href={appleTouchIconPath} />
        <link rel="shortcut icon" href={faviconPath} />
      </Head>

      {children}
    </div>
  );
};
