import {
  getClickIdFromQuery,
  getSourceFromQuery,
  isPositiveInteger,
} from '@insurance/utils';
import { type TSessionIdState } from './types';
import { SESSION_ID_LIFETIME_MS } from './constants';

export const isSessionIdStateValid = (
  state: Partial<TSessionIdState>,
): state is TSessionIdState => {
  const { sessionId, updatedAt, clickId, source } = state;

  return (
    typeof sessionId === 'string' &&
    sessionId.length > 0 &&
    isPositiveInteger(updatedAt) &&
    updatedAt + SESSION_ID_LIFETIME_MS > Date.now() &&
    clickId === getClickIdFromQuery() &&
    source === getSourceFromQuery()
  );
};
