import type { TContactPhone } from './types';

export const defaultContactPhone: TContactPhone = {
  href: 'tel:+18336151896',
  label: '833-615-1896',
};

export const contactPhoneTikTok: TContactPhone = {
  href: 'tel:+18333912074',
  label: '833-391-2074',
};

export const contactPhoneFacebook: TContactPhone = {
  href: 'tel:+18333131428',
  label: '833-313-1428',
};

export const contactPhoneQuinstreet: TContactPhone = {
  href: 'tel:+18558436725',
  label: '855-843-6725',
};
