export interface TUtmParams {
  utmSource?: string;
  utmMedium?: string;
  utmCampaign?: string;
  utmContent?: string;
}

export interface TAdjustParams {
  adid?: string;
  idfa?: string;
  gpsAdid?: string;
  amazonAdid?: string;
}

export enum InsuranceHost {
  Requote = 'requote.com',
  MyInsuranceMarket = 'myinsurancemarket.com',
  Scalefind = 'insurance.scalefind.com',
  CarSurefy = 'carsurefy.com',
  MyAutoArmor = 'myautoarmor.com',
  AllCheapQuotes = 'allcheapquotes.com',
  SolaceAuto = 'solaceauto.com',
  PragmaAuto = 'pragmaauto.com',
}
