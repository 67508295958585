import { isError } from './is-error';

const fallbackMessage = 'Unknown error';

export const extractErrorMessage = (error: unknown): string => {
  if (isError(error)) {
    return error.message;
  }

  if (typeof error === 'string') {
    return error;
  }

  return fallbackMessage;
};
