/* eslint-disable no-console */
import * as Sentry from '@sentry/nextjs';
import { extractErrorMessage } from '@insurance/utils';
import { isProductionLocation, isStagingLocation } from '@/utils/app';

export const logger = {
  error: (error: unknown, description?: string): void => {
    let errorMessage = extractErrorMessage(error);
    if (description) {
      errorMessage = `${description}: ${errorMessage}`;
    }

    if (isStagingLocation() || isProductionLocation()) {
      if (description) {
        Sentry.captureException(new Error(errorMessage, { cause: error }));
      } else {
        Sentry.captureException(error);
      }
    } else {
      console.error(errorMessage);
    }
  },

  info(...args: Parameters<typeof console.info>): void {
    console.info(...args);
  },

  log(...args: Parameters<typeof console.log>): void {
    console.log(...args);
  },
};
