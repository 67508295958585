import getConfig from 'next/config';

export const isDevelopmentMode = () => process.env.NODE_ENV === 'development';

export const isProductionMode = () => process.env.NODE_ENV === 'production';

export const isStagingLocation = () => {
  const { publicRuntimeConfig } = getConfig();

  return publicRuntimeConfig.buildLocation === 'staging';
};

export const isProductionLocation = () => {
  const { publicRuntimeConfig } = getConfig();

  return publicRuntimeConfig.buildLocation === 'production';
};
