import { type ParsedUrlQuery } from 'node:querystring';
import { URL_PARAMS_ALIASES } from './constants';

export const getSsrParamsWithoutAliases = (
  query: ParsedUrlQuery,
): ParsedUrlQuery => {
  const paramsWithoutAliases: ParsedUrlQuery = {};

  Object.entries(query).forEach(([paramKey, paramValue]) => {
    const origNameForAlias = Object.keys(URL_PARAMS_ALIASES).find((key) =>
      URL_PARAMS_ALIASES[key].includes(paramKey),
    );

    if (origNameForAlias) {
      paramsWithoutAliases[origNameForAlias] = paramValue;
    } else {
      paramsWithoutAliases[paramKey] = paramValue;
    }
  });

  return paramsWithoutAliases;
};
