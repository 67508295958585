import { type ParsedUrlQuery } from 'node:querystring';
import { getSsrParamsWithoutAliases } from './get-ssr-params-without-aliases';

export const getSsrUrlParam = (
  query: ParsedUrlQuery,
  key: string,
): string | undefined => {
  const paramsWithoutAliases = getSsrParamsWithoutAliases(query);

  return paramsWithoutAliases[key] as string;
};
