import { getUrlParam } from './get-url-param';
import type { TAdjustParams } from './types';

export const getAdjustParamsFromQuery = (): TAdjustParams => {
  const adid = getUrlParam('adjust_adid');
  const idfa = getUrlParam('adjust_idfa');
  const gpsAdid = getUrlParam('adjust_gps_adid');
  const amazonAdid = getUrlParam('adjust_amazon_adid');

  return {
    ...(adid ? { adid } : {}),
    ...(idfa ? { idfa } : {}),
    ...(gpsAdid ? { gpsAdid } : {}),
    ...(amazonAdid ? { amazonAdid } : {}),
  };
};
