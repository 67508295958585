import type { PropsWithChildren } from 'react';
import React, { createContext } from 'react';
import { InsuranceHost } from '@insurance/utils';
import { defaultContactPhone } from '@insurance/utils/src/contact/contacts';
import type { TAppConfig } from './types';

const defaultAppConfig: TAppConfig = {
  host: InsuranceHost.Requote,
  formBuildTag: '',
  usStateParam: '',
  needToHideSignUpButton: false,
  contactPhone: defaultContactPhone,
  isSavvyPartnerMode: false,
};

export const AppConfigContext = createContext<TAppConfig>(defaultAppConfig);

interface TAppConfigProps extends PropsWithChildren {
  config: TAppConfig;
}

export const AppConfigProvider: React.FC<TAppConfigProps> = ({
  children,
  config,
}) => (
  <AppConfigContext.Provider value={config}>
    {children}
  </AppConfigContext.Provider>
);
