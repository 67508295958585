import {
  canUseDom,
  getClickIdFromQuery,
  getSourceFromQuery,
} from '@insurance/utils';
import { type TSessionIdState } from './types';
import { generateSessionId } from './generate-session-id';
import { isSessionIdStateValid } from './is-session-id-state-valid';
import { loadSessionIdState } from './load-session-id-state';
import { updateSessionIdState } from './update-session-id-state';

let sessionId: string | undefined;

export const getSessionId = (): string => {
  if (!canUseDom()) {
    throw new Error('getSessionId cannot be used outside of browser context');
  }

  if (sessionId) {
    return sessionId;
  }

  const prevState = loadSessionIdState();

  const state: TSessionIdState = isSessionIdStateValid(prevState)
    ? prevState
    : {
        sessionId: generateSessionId(),
        updatedAt: Date.now(),
        clickId: getClickIdFromQuery(),
        source: getSourceFromQuery(),
      };

  updateSessionIdState(state);

  sessionId = state.sessionId;

  return sessionId;
};
